import { getErrorMessagesFromError, useNuxtApp } from "#imports";
import { useQueryClient } from "@tanstack/vue-query";
import { createQuery, createMutation } from "~/utils/queryUtils";
import useNotify from "~/composables/useNotify";
import { QUERY_KEYS } from "~/utils/queryKeys";

import type { RequestObject } from "../models/utils/Api.model";
import type { MyQueryOptions } from "~/utils/queryUtils";
import type { InternalApi } from "nitropack";
import type { UpsertPolicyPayload } from "~/server/utils/policy";

export const usePolicyService = () => {
  const { $api } = useNuxtApp();
  const queryClient = useQueryClient();
  const { notifyError, notifySuccess } = useNotify();

  const listPolicies = (req: RequestObject, signal?: AbortSignal) =>
    $api<InternalApi["/api/hcd/policies"]["get"]>(`/api/hcd/policies/`, {
      query: {
        page: req.page,
        size: req.pageSize,
        name: req.search,
        sortBy: req.sortBy,
        sortOrder: req.sortOrder,
      },
      signal,
    });

  // List Policies
  const useListPoliciesQuery = (
    req: RequestObject = {
      page: 1,
      pageSize: 20,
    },
    createNuxtError = true
  ) =>
    createQuery(
      [QUERY_KEYS.Policy.list],
      ({ signal }) => listPolicies(req, signal),
      {
        createNuxtError,
      }
    );

  // Get Policy
  const getPolicyEmbedded = (id: string, signal?: AbortSignal) =>
    $api<InternalApi["/api/hcd/policies/:id"]["get"]>(
      `/api/hcd/policies/${id}?include=all`,
      { signal }
    );

  const useGetPolicyQuery = (
    id: string,
    createNuxtError = true,
    options?: MyQueryOptions
  ) =>
    createQuery(
      [QUERY_KEYS.Policy.get, id],
      ({ signal }) => getPolicyEmbedded(id, signal),
      {
        ...options,
        createNuxtError,
      }
    );

  // Enable / Disable policy
  const setPolicyStatus = async (
    id: string,
    enable: boolean,
    signal?: AbortSignal
  ) =>
    await $api(`/api/hcd/policies/${id}/${enable ? "enable" : "disable"}`, {
      method: "PUT",
      signal,
    });

  const useSetPolicyMutation = () =>
    createMutation(
      ({ id, enable }: { id: string; enable: boolean }) =>
        setPolicyStatus(id, enable),
      {
        onSuccess: (_x, y) => {
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.Policy.list],
          });
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.Policy.get, y.id],
          });
          notifySuccess(
            "Success",
            `Policy has been ${y.enable ? "enabled" : "disabled"}`
          );
        },
        onError: (error, y) => {
          notifyError(`Error ${y.enable ? "enabling" : "disabling"} policy`);
        },
      }
    );

  // Add policy
  const addPolicy = async (payload: UpsertPolicyPayload) =>
    await $api<InternalApi["/api/hcd/policies"]["post"]>(`/api/hcd/policies`, {
      method: "POST",
      body: payload,
    });

  const useAddPolicyMutation = () =>
    createMutation((payload: UpsertPolicyPayload) => addPolicy(payload), {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.Policy.list],
        });
        notifySuccess("Success", "Policy added");
      },
      onError: (error) =>
        notifyError(getErrorMessagesFromError(error).join("\n")),
    });

  // Update Policy
  const updatePolicy = async (id: string, payload: UpsertPolicyPayload) =>
    await $api(`/api/hcd/policies/${id}`, {
      method: "PUT",
      body: payload,
    });

  const useUpdatePolicyMutation = () =>
    createMutation(
      ({ id, payload }: { id: string; payload: UpsertPolicyPayload }) =>
        updatePolicy(id, payload),
      {
        onSuccess: (_x, y) => {
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.Policy.get, y.id],
          });
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.Policy.list],
          });
          notifySuccess("Success", "Policy updated");
        },
        onError: (error) =>
          notifyError(getErrorMessagesFromError(error).join("\n")),
      }
    );

  // Delete policy
  const deletePolicy = async (id: string) =>
    await $api(`/api/hcd/policies/${id}`, {
      method: "DELETE",
    });

  const useDeletePolicyMutation = () =>
    createMutation(({ id }: { id: string }) => deletePolicy(id), {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.Policy.list],
        });
        notifySuccess("Success", "Policy deleted");
      },
      onError: () => {
        notifyError(`Error deleting policy`);
      },
    });

  return {
    useListPoliciesQuery,
    listPolicies,
    useGetPolicyQuery,
    useSetPolicyMutation,
    useDeletePolicyMutation,
    deletePolicy,
    useAddPolicyMutation,
    useUpdatePolicyMutation,
  };
};
